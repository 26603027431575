<template>
<main class="content container">
  <p class="text-center">
    Bestellnummer #<strong>{{ orderId }}</strong> Summe: <span class="text-danger">{{ $filters.currency(price.total + montagePrice) }}</span>
    <component :is="paymentType.label"></component>
  </p>
</main>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'
import Paypal from './payment/paypal/paypal'
export default {
  components: {
    Paypal
  },
  setup () {
    useMeta({
      title: 'Zahlung'
    })
    const store = useStore()

    const orderId = computed(() => {
      return store.getters['checkout/orderId']
    })
    const price = computed(() => {
      return store.getters['checkout/price']
    })
    const montagePrice = computed(() => {
      return store.getters['checkout/montagePrice']
    })
    const paymentType = computed(() => {
      return store.getters['checkout/paymentType']
    })
    return {
      orderId,
      price,
      montagePrice,
      paymentType
    }
  }
}
</script>
